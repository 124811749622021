import React from "react";

import Grid from "./grid";
import Title from "./title";
// import Bars from "./bars";
import Section from "./section";
import style from "../style/content.scss";
import Phone from "./../assets/phone.svg";

export default class Content extends React.Component {
  constructor() {
    super();

    this.state = {
      projects: [],
      bars: [],
      age: new Date().getFullYear() - 2002
    };

    fetch(`${window.location.origin}/content/projects.json`)
      .then(response => response.json())
      .then(projectsData => {
        this.setState((currentState) => {
          currentState.projects = projectsData;
          return currentState;
        });
      });

    // fetch(`${window.location.origin}/content/bars.json`)
    //   .then(response => response.json())
    //   .then(barsData => {
    //     this.setState((currentState) => {
    //       currentState.bars = barsData;
    //       return currentState;
    //     });
    //   });
  }

  render() {
    return (
      <div style={style}>
        <div className="center-block">
          <Title/>

          <Section title="Hi, I'm Jonas">
            I am a computer science student with a solid foundation in web development.
            <br />
            <br />
            From time to time I like to reinvent the wheel. This has lead me to build compilers, UI frameworks, neural networks and particle systems from scratch.
          </Section>

          <Section>
            <img src={Phone} alt="phone" className="main-img"/>
          </Section>

          <div id="projects">
            <Section title="Projects">
              I spend a lot of my spare time coding recreationally. Over the past couple of years I've finished quite a few projects.
              Some of them are open source.
              <br/><br/>
              I'd like to show you some.
            </Section>
          </div>
        </div>

        {/* Using .center for scaling reasons */}
        <div className="center">
          {Grid(this.state.projects)}
        </div>

        {/*<div className="center-block">*/}
        {/*  <Section title="Experience">*/}
        {/*    I had the opportunity to work with some <b>amazing</b> people and gain knowledge through courses, both online and at school, but also by working at <a href="https://dynatrace.com" alt="Dynatrace">Dynatrace</a> and <a href="https://grandgarage.eu" alt="Grand Garage">Grand Garage</a>.*/}
        {/*    <br />*/}
        {/*    <br />*/}
        {/*    <b>I'm eager</b> to apply my skills to any project I can manage.*/}
        {/*    <br /><br />*/}
        {/*    The bar graphs below are <b>my estimations</b> of my dexterity with each technology.*/}
        {/*  </Section>*/}

        {/*  <Fade>*/}
        {/*    <Bars data={this.state.bars} />*/}
        {/*  </Fade>*/}
        {/*</div>*/}
      </div>
    );
  }
}
